/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "study-maps-hearing-loss-across-the-us",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#study-maps-hearing-loss-across-the-us",
    "aria-label": "study maps hearing loss across the us permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Study maps hearing loss across the US"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/us-population-affected-by-hearing-loss.png",
    alt: "Map showing hearing loss across the US",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to a recent study mapping bilateral hearing loss prevalence across the United States at the state and county levels, rates of hearing impairment are notably elevated among men, non-Hispanic Whites, and individuals residing in rural regions. Bilateral hearing loss refers to impaired hearing in both ears."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Data from the Sound Check Map reveals that West Virginia, Alaska, Wyoming, Oklahoma, and Arizona exhibit the highest rates of hearing loss, whereas the District of Columbia, New Jersey, New York, Maryland, and Connecticut have the lowest prevalence."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The study revealed that, in addition to mining, occupations in retail and restaurant settings are notably linked with hearing loss. Furthermore, it highlighted a significant increase in hearing loss prevalence starting at age 35."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Published in The Lancet Regional Health – Americas, the research supporting the development of the prevalence map underscores its importance."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Conducted by researchers at NORC at the University of Chicago and supported by the Centers for Disease Control and Prevention, the Sound Check study found that over 38 million Americans—more than one in nine—experience some form of bilateral hearing loss. This rate rises to nearly one in seven for individuals aged 35 and above, and to approximately one in 1.4 for those aged 75 and older."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "David Rein, senior fellow at NORC at the University of Chicago and principal investigator of the study, stresses the national significance of these findings. He notes that while aging is the primary risk factor for hearing loss, geographical location also plays a role. Surprisingly, urban areas with high population density and traffic noise exhibit lower prevalence of hearing loss compared to rural areas."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The researchers advocate for targeted prevention, diagnosis, and treatment strategies based on their state and county-level estimates. Karl White, director of the National Center for Hearing Assessment and Management at Utah State University, urges public health officials to prioritize improved screening for adults, paralleling efforts made for newborns and young children."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The study points out that individuals in rural areas face heightened risk due to noise exposure from outdoor activities such as operating heavy machinery and engaging in recreational pursuits like ATV riding and hunting. However, consistent use of hearing protection can mitigate this risk, even among recreational firearm users."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss significantly impacts health and quality of life, hindering speech and language development in children, increasing family stress, and contributing to social and workplace challenges among adults. Additionally, in older adults, it's associated with a higher risk of dementia, depression, falls, heart attacks, and premature death."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Smiley emphasizes the importance of acknowledging the emotional, social, and cognitive toll of hearing loss. Prevention measures are crucial, and most forms of hearing loss can be effectively treated, allowing individuals to enjoy life without struggling to hear."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-specialist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-specialist",
    "aria-label": "contact a hearing specialist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hearing specialist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you suspect that you or a loved one may be experiencing hearing loss, don't hesitate to take the next step towards a better quality of life. At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we're committed to guiding you through the entire journey of finding the perfect hearing aids tailored to your specific needs. Our team includes the top 2% of audiologists in the United States, ensuring you receive expert care and support. Reclaim the sounds of your life—reach out to hear.com today for a personalized and transformative hearing experience. Your path to better hearing starts here."), "\n", React.createElement(LandingPageCta, {
    copy: "SIGN UP TO TRY HEARING AIDS",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
